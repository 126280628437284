import type { FunctionComponent } from 'react'
import type { FileListResult } from '../data/content/FileListFragment'
import { Button } from './Button'
import { Container } from './Container'
import s from './Downloads.module.sass'
import { Icon } from './Icon'

export type DownloadsProps = {
	title: string
	fileList: FileListResult
}

export const Downloads: FunctionComponent<DownloadsProps> = ({ title, fileList }) => {
	return (
		<Container size="wide">
			<div className={s.Downloads} data-files-count={fileList.items.length}>
				<h3 className={s.Title}>{title}</h3>
				<div className={s.Files} data-files-count={fileList.items.length}>
					{fileList.items.map((file) => {
						if (!file.url) {
							return null
						}

						const params = new URLSearchParams()
						params.append('url', file.url)
						if (file.name) {
							params.append('filename', file.name)
						}

						const href = file.fileType?.includes('pdf')
							? `/api/download_pdf?${params.toString()}`
							: file.url
						return (
							<a
								key={file.id}
								className={s.File}
								href={href}
								download={file.name}
								target="_blank"
								rel="noreferrer">
								<div className={s.FileImage}>
									<Icon name="file" />
								</div>
								<div className={s.FileNameBox}>
									<p className={s.FileName}>{file.name}</p>
									<div className={s.Button}>
										<Button text="Stáhnout" />
									</div>
								</div>
							</a>
						)
					})}
				</div>
			</div>
		</Container>
	)
}
